

























import { Vue, Component, Ref } from 'vue-property-decorator';

@Component({
  name: 'Page',
})
export class Page extends Vue {
  @Ref('page-header')
  protected readonly pageHeaderRef!: HTMLDivElement;

  protected get title(): string | null {
    if (!this.$route.meta?.title) return null;
    return (typeof this.$route.meta?.title === 'function')
      ? this.$route.meta.title.apply(this)
      : this.$route.meta.title;
  }

  protected get subtitle(): string | null {
    if (!this.$route.meta?.subtitle) return null;
    return (typeof this.$route.meta?.subtitle === 'function')
      ? this.$route.meta.subtitle.apply(this)
      : this.$route.meta.subtitle;
  }
}

export default Page;
