<template>
  <div class="home__wrapper">
    <page class="home">
      <v-row justify="start">
        <v-col
          v-for="card in reports"
          :key="card.title"
          col="12"
          sm="6"
          md="4"
        >
          <v-hover>
            <template #default="{ hover }">
              <v-card>
                <v-img
                  class="align-end"
                  height="20rem"
                  :src="card.image"
                  contain
                />
                <v-card-title>{{ card.title }}</v-card-title>
                <v-card-subtitle class="pb-0">
                  {{ card.category }}
                </v-card-subtitle>
                <v-card-text class="text--primary">
                  <div>{{ card.body }}</div>
                </v-card-text>
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="primary"
                  >
                    <v-btn
                      v-for="{ action, title: label } in card.actions"
                      :key="label"
                      color="accent"
                      @click="() => $router.push({ name: action })"
                    >
                      {{ label }}
                    </v-btn>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </page>
  </div>
</template>

<script>
import { Utility } from '@/tools/Utility';
import { Page } from '@/components/Page';

export default {
  name: 'Home',
  components: {
    Page,
  },
  data() {
    return {
      applicationName: '',
      title: 'Dashboard',
      subtitle: 'Welcome to the Vancouver Coastal Health PPE Order-form dashboard!',
      reports: [
        {
          title: 'Order Summary',
          category: 'Report',
          image: '/img/png/data-vis-1.png',
          body: 'Generate, export and visualize order summary',
          actions: [
            {
              title: 'View Report',
              action: 'order-summary',
            },
          ],
        },
        {
          title: 'PPE Supply By Category',
          category: 'Report',
          image: '/img/png/data-vis-2.png',
          body: 'Organize and visualize supply data by category',
          actions: [
            {
              title: 'View Report',
              action: 'ppe-supply-by-category',
            },
          ],
        },
        {
          title: 'PPE Supply By SKU',
          category: 'Report',
          image: '/img/png/data-vis-3.png',
          body: 'Organize and visualize supply data by SKU',
          actions: [
            {
              title: 'View Report',
              action: 'ppe-supply-by-sku',
            },
          ],
        },
        {
          title: 'PHSA Order Calculation',
          category: 'Report',
          image: '/img/png/data-vis-4.png',
          body: 'Organize and visualize bi-weekly orders',
          actions: [
            {
              title: 'View Report',
              action: 'phsa-order-calculation',
            },
          ],
        },
        {
          title: 'EOC PPE Supply',
          category: 'Report',
          image: '/img/png/data-vis-5.png',
          body: 'Custom categorization of PPE Supply for Regional EOC',
          actions: [
            {
              title: 'View Report',
              action: 'eoc-ppe-supply',
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const config = await Utility.fetchConfig();
      this.applicationName = config.portal.name;
    },
  },
};
</script>
